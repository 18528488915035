import IconButton from "@material-ui/core/IconButton";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import React, { useContext, useEffect, useState } from "react";
import Loading from "../../../components/Loading";
import SearchInput from "../../../components/SeachInput";

//components
import Table from "../../../components/Table";
//contexts
import AlertContext from "../../../contexts/Alert/AlertContext";
//services
import Api from "../../../service/api";
import { response_translate } from "../../../Tools/responseHandler";
//styles
import UsersContainer from "./styles";
import { useHistory } from 'react-router-dom';
import AuthContext from '../../../contexts/Auth/AuthContext';

export default function Users() {
  const { setAlert } = useContext(AlertContext);

  const { setAdminAuth } = useContext(AuthContext);
  const [rows, setRows] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [fields, setFields] = useState({
    fields: {
      firstField: "",
      secondField: "",
    },
  });

  const [update, forceUpdate] = useState();

  const header = [
    "ID",
    fields.secondField,
    "Nome",
    "Divisao",
    "Departamento",
    "Reivindicado",
    "Status",
  ];
  const width = Math.ceil(100 / header.length);

  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    Api.get("/admin/usersperpage")
      .then((res) => {
        setRows(res.data.users);
        setQuery("");
        setLoading(false);
      })
      .catch((err) => {
        setAlert({
          type: "error",
          severity: "error",
          message: response_translate(err.response.data.message),
          show: true,
        });
        setLoading(false);
      });
  }, [setAlert, update]);

  useEffect(() => {
    Api.get("/admin/loginFields").then((response) => {
      setFields(response.data.fields);
    });
  }, []);

  useEffect(() => {
    let row = rows.filter(
      (row) =>
        row.name.toLowerCase().includes(query.toLowerCase()) ||
        row.id.includes(query)
    );
    if (row.length === 0 && query !== "") {
      Api.get(`/admin/users/${query}`)
        .then((res) => {
          const users = res.data.map((user) => ({
            id: user.cpData.id,
            numDoc: user.cpData.num_doc,
            name: user.wpData.core.name,
            division: user.wpData.enterprise.division,
            department: user.wpData.enterprise.department,
            claimed: user.wpData.accountStatusDetails.claimed,
            active: user.wpData.core.active,
          }));

          setRows(users);
        })
        .catch((err) => {
          setAlert({
            type: "error",
            severity: "error",
            message: `Usuário com ID: ${query} não foi encontrado.`,
            show: true,
          });
          setQuery("");
        });
    } else {
      setRows(row);
    }
  }, [query, setAlert]);
  const Thead = () => {
    return (
      <>
        {loading ? <Loading /> : ""}
        <div className="thead" style={{ opacity: loading === true ? ".3" : 1 }}>
          <div className="tr">
            <div className="th" style={{ width: `${width}%` }} key={0}>
              Índice
            </div>
            {header.map((header, index) => (
              <div
                className="th"
                style={{ width: `${width}%` }}
                key={index + 1}
              >
                {header}
              </div>
            ))}
            <div className="th" style={{ width: `${width}%` }} key={7}>
              Ações
            </div>
          </div>
        </div>
      </>
    );
  };
  const handle_activation = async ({ numDoc, ...payload }) => {
    setLoading(true);
    await Api.put(
      "/admin/users",
      { ...payload, num_doc: numDoc, active: true },
    ).catch((err) => {
      setLoading(false);
      setAlert({
        type: "error",
        severity: "error",
        message: response_translate(err.response.data.msg),
        show: true,
      });
    });
    forceUpdate(Math.random());
  };

  const handle_desativation = async ({ numDoc, ...payload }) => {
    setLoading(true);
    await Api.put(
      "/admin/users",
      { ...payload, num_doc: numDoc, active: false },
    ).catch((err) => {
      setLoading(false);
      setAlert({
        type: "error",
        severity: "error",
        message: response_translate(err.response.data.msg),
        show: true,
      });
    });
    forceUpdate(Math.random());
  };

  const Tbody = ({ handle_click }) => {
    return (
      <div className="tbody" style={{ opacity: loading === true ? ".3" : 1 }}>
        {rows.map((row, index) => (
          <div className="tr" key={index}>
            <div className="td" style={{ width: `${width}%` }}></div>

            <div className="td" style={{ width: `${width}%` }}>
              {row.id}
            </div>
            <div className="td" style={{ width: `${width}%` }}>
              {row.numDoc}
            </div>
            <div className="td" style={{ width: `${width}%` }}>
              {row.name}
            </div>
            <div className="td" style={{ width: `${width}%` }}>
              {row.division}
            </div>
            <div className="td" style={{ width: `${width}%` }}>
              {row.department}
            </div>
            <div className="td" style={{ width: `${width}%` }}>
              {row.claimed ? "Sim" : "Não"}
            </div>
            <div className="td" style={{ width: `${width}%` }}>
              {row.active ? "Ativado" : "Desativado"}
            </div>
            {/* Actions buttons Icons */}
            <div className="td" style={{ width: `${width}%` }}>
              <IconButton
                size="small"
                color="inherit"
                onClick={() => handle_activation(row)}
                title="Ativar Usuário"
              >
                <CheckCircleOutlineIcon fontSize="large" />
              </IconButton>
              <IconButton
                size="small"
                color="inherit"
                onClick={() => handle_desativation(row)}
                title="Desativar Usuário"
              >
                <BlockIcon fontSize="large" />
              </IconButton>
              <IconButton
                size="small"
                color="inherit"
                onClick={() => handle_click(row)}
                title="Editar Usuário"
              >
                <EditIcon fontSize="large" />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
    );
  };

  async function nextPage() {
    setLoading(true);
    setPage(page + 1);
    await Api.get("/admin/usersperpage", {
      params: {
        nextOrPrev: "next",
        lastUser: rows[rows.length - 1],
        page: page,
      },
    })
      .then((res) => {
        setRows(res.data.users);
        setQuery("");
        setLoading(false);
      })
      .catch((err) => {
        setAlert({
          type: "error",
          severity: "error",
          message: response_translate(err.response),
          show: true,
        });
        setLoading(false);
      });
  }

  async function prevPage() {
    setLoading(true);
    setPage(page - 1);
    await Api.get("/admin/usersperpage", {
      params: {
        nextOrPrev: "prev",
        firstUser: rows[0],
        page: page - 1,
      },
    })
      .then((res) => {
        setRows(res.data.users);
        setQuery("");
        setLoading(false);
      })
      .catch((err) => {
        setAlert({
          type: "error",
          severity: "error",
          message: response_translate(err.response),
          show: true,
        });
        setLoading(false);
      });
  }

  return (
    <UsersContainer>
      <div
        style={{
          paddingLeft: "20px",
          width: "100%",
          alignItems: "center",
          display: "flex",
        }}
      >
        <SearchInput
          setQuery={setQuery}
          searchInputPlaceholder={"Pesquise por nome ou ID"}
          forceUpdate={forceUpdate}
          nextPage={nextPage}
          prevPage={prevPage}
          page={page}
        />
      </div>
      <Table
        Tbody={Tbody}
        Thead={Thead}
        setQuery={setQuery}
        forceUpdate={forceUpdate}
        is_clickable
      />
    </UsersContainer>
  );
}
