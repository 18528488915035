import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from './AuthContext';
import api from '../../service/api';
import Cookies from 'js-cookie';

export default function AuthProvider({ children }) {
  const history = useHistory();
  const [userAuth, setUserAuth] = useState(false);
  const [adminAuth, setAdminAuth] = useState(false);
  const [userInformation, setUserInformation] = useState({});

  const logout = () => {
    api.post("/admin/logout");
    setAdminAuth(false);
    localStorage.removeItem('loggedIn');
    Cookies.remove('@user');
    history.push('/admin');
  }


  return (
    <AuthContext.Provider
      value={{
        userAuth,
        setUserAuth,
        userInformation,
        setUserInformation,
        adminAuth,
        setAdminAuth,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
