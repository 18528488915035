import styled from "styled-components";

export default styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border-radius: 5px;
  background: ${(props) => props.theme.background};
  padding: 20px;
  scroll-behavior: smooth;
  .AddUser {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .left_pane {
    width: 25%;
    font-size: 1.5rem;
    background: ${(props) => props.theme.primary};
    position: fixed;
    height: 185px;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.border};
  }

  .left_pane ul {
    list-style: none;
    width: 100%;
    padding: 10px 0;
  }

  .left_pane ul li {
    width: 100%;
    padding: 10px;
    margin-bottom: 0.5rem;
    background: ${(props) => props.theme.hover};
    transition: 200ms ease;
  }

  .left_pane ul li a {
    color: ${(props) => props.theme.text};
    width: 100%;
    display: block;
  }

  .left_pane ul li:hover {
    cursor: pointer;
    background: transparent;
  }

  .right_pane {
    width: 75%;
    margin-left: 35%;
  }

  #general,
  #authentication,
  #security,
  #themes,
  #alerts,
  #contact,
  #login-fields {
    background: ${(props) => props.theme.primary};
    border: 1px solid ${(props) => props.theme.border};
    margin-bottom: 1rem;
    border-radius: 5px;
    border-top: 5px solid ${(props) => props.theme.complementary};
  }

  .right_pane .header {
    padding: 15px;
  }

  .right_pane .section {
    width: 100%;
    border-top: 1px solid ${(props) => props.theme.border};
    border-bottom: 1px solid ${(props) => props.theme.border};
    padding: 15px;
  }

  .right_pane .section form h2 {
    margin-bottom: 1rem;
  }

  .right_pane .section form div {
    margin-bottom: 0.5rem;
  }

  .right_pane .section form div p,
  .right_pane .section form div input {
    width: 50%;
  }

  .right_pane .section form div input[type="submit"] {
    width: 30%;
  }

  .right_pane .section form div input[type="text"] {
    border: none;
    border-radius: 3px;
    background: ${(props) => props.theme.background};
    padding: 5px;
    color: ${(props) => props.theme.text};
    border: 1px solid ${(props) => props.theme.hover};
  }

  .right_pane .section form div input[type="password"] {
    width: 25%;
    margin-right: 3px;
    border: none;
    border-radius: 3px;
    background: ${(props) => props.theme.background};
    padding: 5px;
    color: ${(props) => props.theme.text};
    border: 1px solid ${(props) => props.theme.hover};
  }

  .right_pane .section button {
    cursor: pointer;
  }

  .section_title {
    margin-bottom: 3rem;
  }

  .pickers_title {
    width: 100%;
  }

  .pickers .twitter-picker {
    margin-right: 5px;
  }

  .section_title_options {
    width: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  //flex
  display: flex;

  .right_pane .section form div {
    display: flex;
    align-items: flex-start;
  }

  .twitter-picker div:nth-child(3) {
    display: flex;
    flex-wrap: wrap;
  }
  .twitter-picker div:nth-child(2) {
    visibility: hidden;
  }

  .loginConfigContainer {
    input:nth-child(2) {
      margin-top: 8px;
      margin-bottom: 8px;
    }
    input:nth-child(4) {
      margin-bottom: 8px;
      margin-top: 8px;
    }
  }
  .loginInputButton {
    margin-top: 20px;
  }

  .section_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .color_picker_holder {
    display: flex;
    flex-direction: column;
  }

  .pickers_title {
    display: flex;
    justify-content: space-between;
  }

  .contact_container {
    display: flex;
    flex-direction: column;
  }

  .contact_container input {
    padding-left: 10px;
  }

  .buttonsContact {
    width: 18%;
    height: 20px;
    border: 1px solid #767676;
    border-radius: 2px;
    margin: 10px 10px 0 0;
  }

  .loginInputButton {
    margin-top: 20;
    width: 15%;
  }

  .numbers_box {
    display: flex;
    flex-direction: row;
    margin: 15px 0;
  }

  .message_contact {
    width: 65%;
    margin-right: 15px;
  }

  .numbers_box input[type="tel"],
  .numbers_box .numbers {
    margin-left: 15px;
  }

  .numbers_box button {
    margin: 10px 0 0 15px;
    width: 15%;
    height: 20px;
    border: 1px solid #767676;
    border-radius: 2px;
  }

  .customID {
    display: flex;
    flex-direction: column;
  }

  .customIDToggle {
    width: 60px;
    height: 30px;
    background-color: #FAFAFA;
    border: 1px solid #CCC;
    border-radius: 15px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all ease 0.2s;
  }

  .customIDToggleCircle {
    height: 23px;
    width: 23px;
    border-radius: 50%;
    margin: 3px;
  }

  .customIDLabel {
    margin: 10px 0 10px;
  }
`;
