import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button';
import WelcomeContact from '../../../components/WelcomeContact';

//contexts

//styles
import WelcomeContainer from './styles';

export default function Welcome() {
  const history = useHistory();

  const signup_or_forgot = (action) => {
    history.push(`/login/${action}`);
  };

  const guide = () => {
    history.push('/guide');
  };

  return (
    <WelcomeContainer>
      <h1>Seja bem-vindo ao Claim Portal!</h1>
      <p>
        Aqui você efetua seu primeiro cadastro para utilizar o Workplace!
        <br />
        <br />
      </p>
      <p>Acesse o Workplace!</p>
      <Button onClick={() => signup_or_forgot('signup')}>Acessar o Workplace</Button>
      {/* <p>
        <br />
        Não sabe como utilizar o Claim Portal?
      </p>
      <Button onClick={guide}>Assistir vídeo</Button> */}

      <WelcomeContact />
    </WelcomeContainer>
  );
}
